import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoiceReportReducerInitialState } from '../reducers/admin-invoice-report.reducer';

const getAdminInvoiceReportReducerState =
  createFeatureSelector<InvoiceReportReducerInitialState>('adminInvoiceReport');

export const getAdminInvoiceReportStore = createSelector(
  getAdminInvoiceReportReducerState,
  (state) => state
);
