import { createAction, props } from '@ngrx/store';
import { InvoiceReportReducerInitialState } from '../reducers/admin-invoice-report.reducer';

const prefix = '[Admin-invoice-report]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<InvoiceReportReducerInitialState>>()
);

export const setListAction = createAction(`${prefix} set list`, props<any>());

export const setListQueryAction = createAction(
  `${prefix} set list query`,
  props<
    Partial<{
      page: number;
      size: number;
      sortOrder: string;
      sortField: string;
      minDate: string;
      maxDate: string;
      status: string;
      rhId: string;
    }>
  >()
);

export const resetListQueryAction = createAction(
  `${prefix} reset list query`,
  props<any>()
);

export const setSearchAction = createAction(
  `${prefix} set search`,
  props<{
    keyword: string;
  }>()
);

export const setDocAction = createAction(
  `${prefix} set doc`,
  props<{ id: string }>()
);

export const setCardsAction = createAction(`${prefix} set cards`, props<any>());
